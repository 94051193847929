/* TODO: BPLAT-152 - Address OLD eslint errors */
/* eslint-disable no-undef */

const SHARED_VALUES = {
  EDIPHI_TENANT: window.location.hostname.split('.')[0],
  EDIPHI_AG_GRID_LICENSE_KEY:
    'Using_this_{AG_Grid}_Enterprise_key_{AG-076073}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Ediphi_Solutions_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Ediphi-Core}_only_for_{10}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Ediphi-Core}_need_to_be_licensed___{Ediphi-Core}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{30_March_2026}____[v3]_[01]_MTc3NDgyNTIwMDAwMA==7164834384b0fe28a8c8fca823d3dfa8',
  EDIPHI_NO_AUTH_URLS: ['/login', '/logout', '/implicit/callback', '/login/magic'],
};

const localEnv = {
  ...SHARED_VALUES,
  EDIPHI_API_URL: 'http://api.localhost:8080',
  EDIPHI_GOOGLE_API_KEY: 'AIzaSyAZBG-z7Ndy8ZgjPjS6J71WhurNHTN8sBw',
  EDIPHI_NODE_ENV: 'development',
  EDIPHI_SENTRY_DSN: '',
  NODE_ENV: 'development',
  EDIPHI_LAUNCHDARKLY_CLIENT_ID: 'default',
  EDIPHI_MIXPANEL_ENABLED: false,
  EDIPHI_MIXPANEL_API_HOST: 'https://events.stage.ediphi.com',
  EDIPHI_MIXPANEL_TOKEN: '540d970149de5ce36cfaa95661401337',
};

const tunneledEnv = {
  ...SHARED_VALUES,
  // EDIPHI_API_URL is set by shared values
  EDIPHI_GOOGLE_API_KEY: 'AIzaSyAZBG-z7Ndy8ZgjPjS6J71WhurNHTN8sBw',
  EDIPHI_NODE_ENV: 'development',
  EDIPHI_SENTRY_DSN: '',
  NODE_ENV: 'development',
  EDIPHI_LAUNCHDARKLY_CLIENT_ID: 'default',
  EDIPHI_MIXPANEL_ENABLED: false,
  EDIPHI_MIXPANEL_API_HOST: 'https://events.stage.ediphi.com',
  EDIPHI_MIXPANEL_TOKEN: '540d970149de5ce36cfaa95661401337',
};

const dev1Env = {
  ...SHARED_VALUES,
  EDIPHI_API_URL: 'https://api.dev1.ediphi.com',
  EDIPHI_GOOGLE_API_KEY: 'AIzaSyAZBG-z7Ndy8ZgjPjS6J71WhurNHTN8sBw',
  EDIPHI_NODE_ENV: 'dev1',
  EDIPHI_SENTRY_DSN:
    'https://745fbb432af3f1b3a05eea88ed9a9a20@o4506299957510144.ingest.sentry.io/4506299968258048',
  NODE_ENV: 'production',

  EDIPHI_DD_APP_ID: '368d2cf9-0c66-468f-9493-4c1230e679c0',
  EDIPHI_DD_CLIENT_TOKEN: 'pubb64c579b725e21b73bbf632c705c84b3',
  EDIPHI_DD_PROXY_HOST: 'https://rum.stage.ediphi.com',
  EDIPHI_LAUNCHDARKLY_CLIENT_ID: '6785764328826e099e5d2967',
  EDIPHI_MIXPANEL_ENABLED: false,
  EDIPHI_MIXPANEL_API_HOST: 'https://events.stage.ediphi.com',
  EDIPHI_MIXPANEL_TOKEN: '540d970149de5ce36cfaa95661401337',

  METICULOUS_RECORDING_TOKEN: 'WRq0DQfpL7dw7ivUIMNjvrFgzY8PGDoURropnERd',
};

const dev2Env = {
  ...SHARED_VALUES,
  EDIPHI_API_URL: 'https://api.dev2.ediphi.com',
  EDIPHI_GOOGLE_API_KEY: 'AIzaSyAZBG-z7Ndy8ZgjPjS6J71WhurNHTN8sBw',
  EDIPHI_NODE_ENV: 'dev2',
  EDIPHI_SENTRY_DSN:
    'https://745fbb432af3f1b3a05eea88ed9a9a20@o4506299957510144.ingest.sentry.io/4506299968258048',
  NODE_ENV: 'production',

  EDIPHI_DD_APP_ID: '368d2cf9-0c66-468f-9493-4c1230e679c0',
  EDIPHI_DD_CLIENT_TOKEN: 'pubb64c579b725e21b73bbf632c705c84b3',
  EDIPHI_DD_PROXY_HOST: 'https://rum.stage.ediphi.com',
  EDIPHI_LAUNCHDARKLY_CLIENT_ID: '6785764328826e099e5d2967',
  EDIPHI_MIXPANEL_ENABLED: false,
  EDIPHI_MIXPANEL_API_HOST: 'https://events.stage.ediphi.com',
  EDIPHI_MIXPANEL_TOKEN: '540d970149de5ce36cfaa95661401337',

  METICULOUS_RECORDING_TOKEN: 'WRq0DQfpL7dw7ivUIMNjvrFgzY8PGDoURropnERd',
};

const dev3Env = {
  ...SHARED_VALUES,
  EDIPHI_API_URL: 'https://api.dev3.ediphi.com',
  EDIPHI_GOOGLE_API_KEY: 'AIzaSyAZBG-z7Ndy8ZgjPjS6J71WhurNHTN8sBw',
  EDIPHI_NODE_ENV: 'dev3',
  EDIPHI_SENTRY_DSN:
    'https://745fbb432af3f1b3a05eea88ed9a9a20@o4506299957510144.ingest.sentry.io/4506299968258048',
  NODE_ENV: 'production',

  EDIPHI_DD_APP_ID: '368d2cf9-0c66-468f-9493-4c1230e679c0',
  EDIPHI_DD_CLIENT_TOKEN: 'pubb64c579b725e21b73bbf632c705c84b3',
  EDIPHI_DD_PROXY_HOST: 'https://rum.stage.ediphi.com',
  EDIPHI_LAUNCHDARKLY_CLIENT_ID: '6785764328826e099e5d2967',
  EDIPHI_MIXPANEL_ENABLED: false,
  EDIPHI_MIXPANEL_API_HOST: 'https://events.stage.ediphi.com',
  EDIPHI_MIXPANEL_TOKEN: '540d970149de5ce36cfaa95661401337',

  METICULOUS_RECORDING_TOKEN: 'WRq0DQfpL7dw7ivUIMNjvrFgzY8PGDoURropnERd',
};

const dev4Env = {
  ...SHARED_VALUES,
  EDIPHI_API_URL: 'https://api.dev4.ediphi.com',
  EDIPHI_GOOGLE_API_KEY: 'AIzaSyAZBG-z7Ndy8ZgjPjS6J71WhurNHTN8sBw',
  EDIPHI_NODE_ENV: 'dev4',
  EDIPHI_SENTRY_DSN:
    'https://745fbb432af3f1b3a05eea88ed9a9a20@o4506299957510144.ingest.sentry.io/4506299968258048',
  NODE_ENV: 'production',

  EDIPHI_DD_APP_ID: '368d2cf9-0c66-468f-9493-4c1230e679c0',
  EDIPHI_DD_CLIENT_TOKEN: 'pubb64c579b725e21b73bbf632c705c84b3',
  EDIPHI_DD_PROXY_HOST: 'https://rum.stage.ediphi.com',
  EDIPHI_LAUNCHDARKLY_CLIENT_ID: '6785764328826e099e5d2967',
  EDIPHI_MIXPANEL_ENABLED: false,
  EDIPHI_MIXPANEL_API_HOST: 'https://events.stage.ediphi.com',
  EDIPHI_MIXPANEL_TOKEN: '540d970149de5ce36cfaa95661401337',

  METICULOUS_RECORDING_TOKEN: 'WRq0DQfpL7dw7ivUIMNjvrFgzY8PGDoURropnERd',
};

const stageEnv = {
  ...SHARED_VALUES,
  EDIPHI_API_URL: 'https://api.stage.ediphi.com',
  EDIPHI_GOOGLE_API_KEY: 'AIzaSyAZBG-z7Ndy8ZgjPjS6J71WhurNHTN8sBw',
  EDIPHI_NODE_ENV: 'stage',
  EDIPHI_SENTRY_DSN:
    'https://745fbb432af3f1b3a05eea88ed9a9a20@o4506299957510144.ingest.sentry.io/4506299968258048',
  NODE_ENV: 'production',

  EDIPHI_DD_APP_ID: '368d2cf9-0c66-468f-9493-4c1230e679c0',
  EDIPHI_DD_CLIENT_TOKEN: 'pubb64c579b725e21b73bbf632c705c84b3',
  EDIPHI_DD_PROXY_HOST: 'https://rum.stage.ediphi.com',
  EDIPHI_LAUNCHDARKLY_CLIENT_ID: '6785764328826e099e5d2967',
  EDIPHI_MIXPANEL_ENABLED: false,
  EDIPHI_MIXPANEL_API_HOST: 'https://events.stage.ediphi.com',
  EDIPHI_MIXPANEL_TOKEN: '540d970149de5ce36cfaa95661401337',

  METICULOUS_RECORDING_TOKEN: 'WRq0DQfpL7dw7ivUIMNjvrFgzY8PGDoURropnERd',
};

const releaseEnv = {
  ...SHARED_VALUES,
  EDIPHI_API_URL: 'https://api.release.ediphi.com',
  EDIPHI_GOOGLE_API_KEY: 'AIzaSyDMAAFqnEgvKLKPRHG8MRRHklbOSwbbaC8',
  EDIPHI_NODE_ENV: 'release',
  EDIPHI_SENTRY_DSN:
    'https://745fbb432af3f1b3a05eea88ed9a9a20@o4506299957510144.ingest.sentry.io/4506299968258048',
  NODE_ENV: 'production',

  EDIPHI_DD_APP_ID: '368d2cf9-0c66-468f-9493-4c1230e679c0',
  EDIPHI_DD_CLIENT_TOKEN: 'pubb64c579b725e21b73bbf632c705c84b3',
  EDIPHI_DD_PROXY_HOST: 'https://rum.ediphi.com',
  EDIPHI_LAUNCHDARKLY_CLIENT_ID: '6785764328826e099e5d2967',
  EDIPHI_MIXPANEL_ENABLED: false,
  EDIPHI_MIXPANEL_API_HOST: 'https://events.ediphi.com',
  EDIPHI_MIXPANEL_TOKEN: '540d970149de5ce36cfaa95661401337',

  METICULOUS_RECORDING_TOKEN: 'WRq0DQfpL7dw7ivUIMNjvrFgzY8PGDoURropnERd',
};

const prodEnv = {
  ...SHARED_VALUES,
  EDIPHI_API_URL: 'https://api.ediphi.com',
  EDIPHI_GOOGLE_API_KEY: 'AIzaSyDMAAFqnEgvKLKPRHG8MRRHklbOSwbbaC8',
  EDIPHI_NODE_ENV: 'prod',
  EDIPHI_SENTRY_DSN:
    'https://745fbb432af3f1b3a05eea88ed9a9a20@o4506299957510144.ingest.sentry.io/4506299968258048',
  NODE_ENV: 'production',

  EDIPHI_DD_APP_ID: '368d2cf9-0c66-468f-9493-4c1230e679c0',
  EDIPHI_DD_CLIENT_TOKEN: 'pubb64c579b725e21b73bbf632c705c84b3',
  EDIPHI_DD_PROXY_HOST: 'https://rum.ediphi.com',
  EDIPHI_LAUNCHDARKLY_CLIENT_ID: '67815f5070c9a309ca9c8bcc',
  EDIPHI_MIXPANEL_ENABLED: true,
  EDIPHI_MIXPANEL_API_HOST: 'https://events.ediphi.com',
  EDIPHI_MIXPANEL_TOKEN: '907da647e4fa4f589b95a9fe2c7885e9',
};

const envVars = {
  local: localEnv,
  tunneled: tunneledEnv,
  dev1: dev1Env,
  dev2: dev2Env,
  dev3: dev3Env,
  dev4: dev4Env,
  stage: stageEnv,
  release: releaseEnv,
  prod: prodEnv,
};

module.exports.getEnvVar = (name) => {
  const currentEnv = envVars[window.config.ENV];

  if (!currentEnv) {
    // As this can be used in the build process, we can't use the Logger
    // eslint-disable-next-line no-console
    console.error(`Environment "${window.config.ENV}" not found in env.js`);

    return undefined;
  }

  // Merge window.config into the current environment configuration
  const mergedConfig = { ...currentEnv, ...window.config };

  return mergedConfig[name] || undefined;
};
